
import DataService from '@/core/services/DataService';

export default {
    name: 'geo-selector',
    async mounted() {
        this.$nextTick(async () => {
            let countries = await DataService.entity.country.getOptions()
            
            // this.countries = Object.assign([], countries);
            // @ts-ignore
            this.countries = window.structuredClone(countries);
            let v = this.modelValue;
            await this.updateSelectedCountries(v);
            
            this.$emit('update:modelValue', v);
        });
    },
    emits: ['update:value'],
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            countries: [],
            regions: {},
            cities: {},
        };
    },
    methods: {
        mergeWithoutDuplicates(oldArr: {name: string}[], newArr: {name: string}[]) {
            const map: any = {};
            oldArr.forEach((entity) => {
                map[entity.name] = entity;
            });
            newArr.forEach((entity) => {
                map[entity.name] = entity;
            });
            return Object.values(map);
        },
        getEntitiesFromSearch(v: string, data_array: any[], currentValuesPointer: any[]): any[] {
            if(!v.includes(",")) {return [];}
            const names = v.split(",").map((name: string) => name.toLowerCase().trim()).filter((name: string) => name);
            const entities: any[] = [];
            for(const data of data_array) {
                const name: string = data.name.toLowerCase();
                if(names.includes(name)) {
                    entities.push(data);
                }
            }
            // return entities;
            const selected = currentValuesPointer || [];
            const merged = this.mergeWithoutDuplicates(selected, entities);
            return merged;
        },

        searchCountry(v: string) {
            const merged = this.getEntitiesFromSearch(v, this.countries, this.modelValue);
            if(merged.length === 0) {return;}
            // create preload method to the cache, which will send array of codes instead of 1 and set all to the cache
            this.updateModel(merged);
        },
        searchRegions(v: string, regions: any[], countryPointer: any) {
            const merged = this.getEntitiesFromSearch(v, regions, countryPointer.regions);
            if(merged.length === 0) {return;}
            
            countryPointer.regions = merged;
            // create preload method to the cache, which will send array of codes instead of 1 and set all to the cache
            this.updateRegionModel(merged);
        },
        searchCities(v: string, cities: any[], regionPointer: any) {
            const merged = this.getEntitiesFromSearch(v, cities, regionPointer.cities);
            if(merged.length === 0) {return;}

            regionPointer.cities = merged;
            this.updateCityModel(merged);
        },

        async updateSelectedRegions(v){
            for (let i=0; i<v.length; i++) {
                if (!v[i].cities){
                    v[i].cities = [];
                }
                if (!this.cities[v[i].id]){
                    this.cities[v[i].id] = await DataService.entity.city.getRegionOptions(v[i].id);
                }
            }
            
        },
        async updateSelectedCountries(v){
            console.log("V:", v);
            for (let i=0; i<v.length; i++){
                if (!v[i].regions){
                    v[i].regions = [];
                }
                if (!this.regions[v[i].code2]){
                    this.regions[v[i].code2] = await DataService.entity.region.getCountryOptions(v[i].code2);
                }
                if (v[i].regions.length){
                    await this.updateSelectedRegions(v[i].regions);
                }
                
                // if (!this.cities[v[i].code2]){
                // 	this.cities[v[i].code2] = {};
                // }
                
            }
        },
        async deselectCountry(v) {
            await this.updateModel(this.modelValue);
        },
        async updateModel(v){
            await this.updateSelectedCountries(v);
            this.$emit('update:modelValue', v);
        },
        async updateRegionModel(v){
            console.log("regionModel:", v);
            await this.updateSelectedRegions(v);
            this.$emit('update:modelValue', this.modelValue);
        },
        updateCityModel(evt){
            this.$emit('update:modelValue', this.modelValue);
        }
    },
    watch: {
        modelValue: {
            handler(v) {
                console.log("MV:", v);
            }, deep: true
        }
    }
}
