
import { defineComponent, ref } from 'vue';
import {TimeframeEntity, HourEntity} from '../interfaces';
import GeoSelector from '@/components/geo-restrictions/GeoSelector.vue';

const defaultRestrictionRange = (): TimeframeEntity[] => {
    const days = ["S", "M", "T", "W", "T", "F", "S"];
    return days.map((day: string) => {
        return {
            day, 
            hours: new Array(24).fill(0).map((_): HourEntity => ({enabled: false}))
        } as TimeframeEntity
    })
};

export default defineComponent({
    components: { GeoSelector },
    props: {
        offer: {
            type: Object,
            default: () => ({
                country: "",
                restrict_timeframe: false,
                restrict_timeframe_mode: "limit",
                restrict_timeframe_timezone: 0,
                restrict_timeframe_schedule: defaultRestrictionRange()
            }),
            required: true
        }
    },
    data() {
        return {
            restrictTimeframeModes: ['limit', 'nolimit']
        }
    },
    setup(props) {
        const offer_ref = ref(props.offer);
        offer_ref.value.restrict_timeframe_schedule = !offer_ref.value.restrict_timeframe_schedule?defaultRestrictionRange():offer_ref.value.restrict_timeframe_schedule;

        if( !offer_ref.value.geo_targeting?.whitelist && !offer_ref.value.geo_targeting?.blacklist) {
            offer_ref.value.geo_targeting.whitelist = [];
            offer_ref.value.geo_targeting.blacklist = [];
        }
        
        return {
            offer_ref
        };
    },
    methods: {
        getNextValue(arr: boolean[]): boolean {
            return !arr.every((v: boolean) => v );
        },
        toggleColumn(num: number) {
                if(this.offer_ref.restrict_timeframe_mode === 'limit') return;
                const currentValues: boolean[] = [];
                for(const row of this.offer_ref.restrict_timeframe_schedule) {
                        currentValues.push(row.hours[num].enabled);
                }
                const val = this.getNextValue(currentValues);
                for(let i = 0; i < this.offer_ref.restrict_timeframe_schedule.length; i++) {
                        this.offer_ref.restrict_timeframe_schedule[i].hours[num].enabled = val;
                }
        },
        toggleRow(num: number) {
                if(this.offer_ref.restrict_timeframe_mode === 'limit') return;
            const currentValues: boolean[] = this.offer_ref.restrict_timeframe_schedule[num].hours.map((h) => h.enabled);
            const val = this.getNextValue(currentValues);
                this.offer_ref.restrict_timeframe_schedule[num].hours = this.offer_ref.restrict_timeframe_schedule[num].hours.map((h) => {
                    h.enabled = val;
                    return h;
                });

        }
    },
})
