

import {defineComponent, onMounted} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {setCurrentPageButtons} from "@/core/helpers/page-buttons";
import DataService from "@/core/services/DataService";
import TargetingTab from './tabs/TargetingTab.vue';

async function loadRoute(vm, to, from, next){
	let offerId = +to.params.id;
	let offer = await DataService.entity.offer.get(offerId);
	return vm => {
		vm.offer = offer;
	};
}

export default defineComponent({
	name: 'offer-view',
	components: { TargetingTab },
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	setup() {
		onMounted(() => {
			setCurrentPageBreadcrumbs('Details', ['Offers']);
			setCurrentPageButtons(null, []);
		});
	},
	data() {
		return {
			busy: false,
			offer: null
		};
	},
	// methods: {
	//
	// },
});
